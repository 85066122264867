import $ from 'jquery';
// import xss from 'xss';
// import moment from 'moment';
import log from '@brent14/mfm';

import dog from './modules/dog/';
import slider from './modules/gh_simple_slider';

// structure of input forms
// -------------------------
// - variables
// - event handlers
// - input required
// - input validate
// - input save
// - payemnt setup
// - payment success

window.$ = $;


// not less than 2 to allow time for animation
var hero_slider = slider.init('.por_slider_container', '.por_slide', 6);











var contact_form_expand = document.querySelector('.por_contact_form_expand');

if(contact_form_expand){

  var open = 0;

  var static_contact = document.querySelectorAll('.por_contact_static');
  static_contact[0].addEventListener("click", open_contact, false);


  document.addEventListener( 'wpcf7mailsent', function( event ) {
      contact_expand_success()
  }, false );

}


function open_contact(event){

  event.preventDefault();
  var browser_width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  var static_contact = document.querySelectorAll('.por_contact_static');
  var parent_static = static_contact[0].parentNode;

  open = open + 1;
  var isOpen = open % 2;

  if(browser_width > 776){

    if(isOpen){
      parent_static.classList.add("open_contact");
    }else{
      parent_static.classList.remove("open_contact");
    }

  }else{

    var url = static_contact[0].href;
    document.location.href = url;

    console.log('take to contact page', browser_width);
  }
}



function contact_expand_success(){

  var contact_success = document.querySelector('.por_contact_form_expand .por_form_success');
  contact_success.style.display = "block";


  var open_form = document.querySelector('.por_contact_form_expand');
  open_form.style.maxHeight = '150px';


  setTimeout(function(){
    var static_contact = document.querySelectorAll('.por_contact_static');
    var parent_static = static_contact[0].parentNode;
    parent_static.classList.remove("open_contact");

  }, 5000);
}


// .por_contact_form_page

var contact_form_page = document.querySelector('.por_contact_form_page');


if(contact_form_page){

  document.addEventListener( 'wpcf7mailsent', function( event ) {

      var contact_form = document.querySelector('.por_contact_form_page form');
      contact_form.style.display = 'none';

      var contact_form_success = document.querySelector('.por_contact_form_page_success');
      contact_form_success.style.display = 'block';

      setTimeout(function(){
        document.location.href = '/';
      }, 5000);

  }, false );

}






















