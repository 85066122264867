'use strict';

var ClassList = require('classlist')

var slider_container;
var slides;
var interval;

var current_slide = 0;
var left_slide;
var right_slide;

var interval_timer;
var wait = false;

// i should pull this out of html and create the dom in javascript
// need this to be created inside a function
// put this in the build, then i can create once i kknow the length of the slides ismore than one

var arrow_right = document.querySelectorAll('.por_slider_right');
var arrow_left = document.querySelectorAll('.por_slider_left');

if(arrow_right[0] || arrow_left[0]){

  arrow_right[0].addEventListener("click", rotate_slide_right, false);
  arrow_left[0].addEventListener("click", rotate_slide_left, false);
}


/**
 * init slider
 * get all sliders and slides
 * @param  {[type]} slide_container get slider container
 * @param  {[type]} slides          get slider slides
 */
function init(_slide_container, _slide, _slide_interval){

  slider_container = document.querySelectorAll(_slide_container);
  slides = document.querySelectorAll(_slide);

  if(slider_container.length != 0 && slides.length > 1){
    interval = _slide_interval;
    set_stage(slides);
    start_timer();
    create_slider_arrows();
  }else{
    // console.log('slider container doesnt exist')
  }
}

function create_slider_arrows(){
  var slider_arrows_container = document.querySelectorAll('.por_slider_arrow');
  slider_arrows_container[0].style.display = 'block';
}

/**
 * put slides in proper position to animate
 * put slider on stage and everything else to left
 */
function set_stage(node_list){
  set_slider_bread(node_list);

  for (var i = 0; i < node_list.length; ++i) {

    if(i == 0){
      var stage_class = ClassList(node_list[0]);
      stage_class.add('animate_stage');

    }else{
      var stage_class = ClassList(node_list[i]);
      stage_class.add('no_animate_left');

    }
  }
}

function set_slider_bread(node_list){

  var dom = "";
  var slider_num;

  for (var i = 0; i < node_list.length; ++i) {
    dom += "<span id='breadcrumb-" + i + "' class='circle js-breadcrumb'></span>";
  }

  var container = document.createElement("div");
  var bread_width = 20 * node_list.length;

  console.log('working')
  container.classList.add('breadcrumb_container');
  // container.classList = "breadcrumb_container";
  container.innerHTML = dom;
  container.style.width = bread_width + 'px';

  slider_container[0].appendChild(container);

  set_current_bread(0);

}

function reset_bread(){
  var all_crumbs = document.querySelectorAll('.js-breadcrumb');

  for (var i = 0; i < all_crumbs.length; ++i) {
    var current_bread = ClassList(all_crumbs[i]);
    current_bread.remove('current');
  }
}


function set_current_bread(slide_num){
  reset_bread();

  var current_crumb = document.querySelectorAll('#breadcrumb-' + slide_num);
  var current_bread = ClassList(current_crumb[0]);
  current_bread.add('current');

}

// stuck on the javascript interval
// i can't seem to get it to fire
// after taht will need to figure out teh animations and what else needs to be cleared


/**
 * start timer for animating slides
 */
function start_timer(){

  var time = interval * 1000;
  interval_timer = window.setInterval( animate , time);

}


function stop_timer(){

  clearInterval(interval_timer);

}


// code to test timing, not sure if need to make this uniform to on click events
// it stays about 3 more seconds right now after a click
// var old_time_seconds;

function animate(){
  // sets default autoscroll to go right
  move_slider_right();

  // var current_time_seconds = new Date().getTime();
  // var time_elapase = ( current_time_seconds - old_time_seconds ) / 1000;

  // old_time_seconds = current_time_seconds;
  // console.log(time_elapase);
}



function rotate_slide_right(ev){
  ev.preventDefault();

  if(!wait){
    stop_timer();
    move_slider_right();
    wait = true;

    // set timeout to stop user from clicking before animation has stopped
    setTimeout(function(){
      wait = false;
      start_timer();
    }, 1000);

    console.log('go right');
  }
}

function rotate_slide_left(ev){
  ev.preventDefault();

  if(!wait){
    stop_timer();
    move_slider_left();
    wait = true;

    // set timeout to stop user from clicking before animation has stopped
    setTimeout(function(){
      wait = false;
      start_timer();
    }, 1000);

    console.log('go left');
  }
}

function counter(dir){

  if(dir === 'right'){

    current_slide = next_slide(current_slide);

    console.log('slide', current_slide);
  }

  if(dir === 'left'){
    current_slide = prev_slide(current_slide);
  }

}


function move_slider_right(){

  var stage_slide = current_slide;
  var left_slide = next_slide(stage_slide);

  clean_stage('right', left_slide);

  setTimeout(function(){
    set_current_bread(left_slide);

    var stage_class = ClassList(slides[stage_slide]);
    var left_slide_class = ClassList(slides[left_slide]);



    stage_class.add('animate_right').remove('animate_stage').remove('animate_left').remove('no_animate_left').remove('no_animate_right');
    left_slide_class.add('animate_stage').remove('animate_left').remove('no_animate_left').remove('animate_right').remove('no_animate_right');

    counter('right');

  }, interval + 100);

}


function move_slider_left(){

  // mechanics of moving left

  var stage_slide = current_slide;
  var right_slide = prev_slide(stage_slide);

  clean_stage('left', right_slide);

  setTimeout(function(){
    set_current_bread(right_slide);

    var stage_class = ClassList(slides[stage_slide]);
    var right_slide_class = ClassList(slides[right_slide]);

    stage_class.add('animate_left').remove('animate_stage').remove('no_animate_left').remove('animate_right').remove('no_animate_right');
    right_slide_class.add('animate_stage').remove('animate_left').remove('no_animate_left').remove('animate_right').remove('no_animate_right');

    counter('left');

  }, interval + 100);
}



function clean_stage(dir, current){

  if(dir === 'right'){
    // setTimeout(function(){
      // move stage to far left
      var slider = ClassList(slides[current]);
      slider.add('no_animate_left').remove('animate_right').remove('animate_stage');

     // }, interval + 1000);
  }

  if(dir === 'left'){
    // setTimeout(function(){
      // move stage to far left
      var slider = ClassList(slides[current]);
      slider.add('no_animate_right').remove('animate_left').remove('animate_stage');

     // }, interval + 1000);

  }
}

function next_slide(slide_num){
  if(slide_num >= slides.length - 1 || slide_num < 0){
    slide_num = 0;
  }else{
    slide_num = slide_num + 1;
  }

  return slide_num;
}


function prev_slide(slide_num){
  if(slide_num <= 0 || slide_num >= slides.length){
    slide_num = slides.length - 1;
  }else{
    slide_num = slide_num - 1;
  }
  return slide_num;
}




module.exports = {
  init,
};
