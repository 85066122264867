exports.talk = function(callback) {
  callback('it even works for dog, which is great, k, i think that was it');
};
// I need to fix this functionality where it takes a while to reload
exports.hi = function() {
  return 'hi';
};

exports.add = function(num1, num2) {
  return num1 + num2;
};
